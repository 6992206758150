const cookieBanner = document.getElementById("cookie-banner");
const detailedCookies = document.getElementById("detailed-cookies");
const acceptSelectedElem = document.getElementById("accept-selected");

addFuncToAllClasses("consent-all-cookies", () => {

    acceptAllCookies();
});

addFuncToAllClasses("customise-cookies", () => {

    seeMoreOptions();
});

addFuncToAllClasses("conesent-selected", () => {

    seeMoreOptions();
});

acceptSelectedElem.addEventListener("click", () => {

    acceptSelected();
});

function seeMoreOptions() {

    cookieBanner.classList.add("hidden");
    detailedCookies.classList.remove("hidden");
}

function acceptAllCookies() {

    cookieBanner.classList.add("hidden");
    detailedCookies.classList.add("hidden");

    addCookie("allowGoogleAnalytics", true);
    addCookie("allowTargetedAds", true);

    document.getElementById("googleAnalyticsToggle").checked = true;
    document.getElementById("targetedAdsToggle").checked = true;

    updateGA4Consent(true);
    // dataLayer.push({ 'Google Analytics': 'true' });
    dataLayer.push({ 'targeted ads': 'true' });
}

function updateGA4Consent(consented) {

    return dataLayer.push({ 'Google Analytics': `'${consented}'`, "event": "consent_status.*" });
}

function acceptSelected() {

    cookieBanner.classList.add("hidden");
    detailedCookies.classList.add("hidden");

    addCookie("allowGoogleAnalytics", document.getElementById("googleAnalyticsToggle").checked);
    addCookie("allowTargetedAds", document.getElementById("targetedAdsToggle").checked);

    updateGA4Consent(document.getElementById("googleAnalyticsToggle").checked);
    // dataLayer.push({ 'Google Analytics': `${document.getElementById("googleAnalyticsToggle").checked}` });
    dataLayer.push({ 'targeted ads': `${document.getElementById("targetedAdsToggle").checked}` });
}

function addFuncToAllClasses(className, func) {

    const elements = document.getElementsByClassName(className);

    for (const elem of elements) {
        elem.addEventListener("click", func);
    }
}

// addCookie("allowGoogleAnalytics", "")
// addCookie("allowTargetedAds", "")

console.log(getCookie("allowGoogleAnalytics"));
console.log(getCookie("allowTargetedAds"));

if (getCookie("allowGoogleAnalytics") == "" || getCookie("allowTargetedAds") == "") {

    setTimeout(() => {

        cookieBanner.classList.remove("hidden");

    }, 100);
}
else {

    console.log(getCookie("allowTargetedAds"));

    updateGA4Consent(getCookie("allowGoogleAnalytics"));
    // dataLayer.push({ 'Google Analytics': `${getCookie("allowGoogleAnalytics")}` });
    dataLayer.push({ 'targeted ads': `${getCookie("allowTargetedAds")}` });

    document.getElementById("googleAnalyticsToggle").checked = stringToBool(getCookie("allowGoogleAnalytics"));
    document.getElementById("targetedAdsToggle").checked = stringToBool(getCookie("allowTargetedAds"));
}

function stringToBool(str) {

    return str == "true" ? true: false;
}